import React, { useEffect } from 'react';
import styled from 'styled-components';

import { desktopBreakpoint, desktopVW, mobileVW } from '../../styles';

const PublitasContainer = ({ first }: any) => {
  useEffect(() => {
    const currentUrl = window.location.href;
    let dataPublication = '';
    if (currentUrl.includes('product-catalogue-1') || currentUrl.includes('product-catalogus-1')) {
      dataPublication = 'https://view.publitas.com/dbodhi/dbodhi_catalogue/';
    } else {
      dataPublication = 'https://view.publitas.com/dbodhi/dbodhi-catalogue-2023/page/6-7';
    }

    const script = document.createElement('script');
    script.src = 'https://view.publitas.com/embed.js';
    script.setAttribute('data-wrapper-id', 'publitas-embed-y5mtize755l');
    script.setAttribute('data-height', 'undefined');
    script.setAttribute('data-width', 'undefined');
    script.setAttribute('data-cfasync', 'false');
    script.setAttribute('data-publication', dataPublication);
    script.setAttribute('data-responsive', 'true');
    script.setAttribute('data-publication-aspect-ratio', '1.4143007360672977');
    script.setAttribute('publitas-embed', 'true');
    script.type = 'text/javascript';
    document.body.appendChild(script);
  }, []);

  return <PublitasContainerDiv id="publitas-embed-y5mtize755l" first={first} />;
};

const PublitasContainerDiv = styled.div`
  height: 100vh;
  width: 100vw;
  margin-top: ${mobileVW(80)};
  ${desktopBreakpoint} {
    margin-top: ${desktopVW(120)};
  }
  @media (max-width: 750px) {
    height: 30vh;
  }
`;

export default PublitasContainer;
