import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  mobileContentSidePadding,
  zIndex,
} from '../../styles/index';
import Icons from '../../utils/Icons';
import { useIsMobile } from '../../hooks';

interface Props {
  mediaSection?: {
    title: string;
    heading?: string;
    subtitle: string;
    paragraph: string;
    video: any;
    videoVimeo: string;
    videoTitle: string;
    image: string;
    invertedLayout?: boolean;
    borderlessImage?: boolean;
    xlImage?: boolean;
    verticallyCenteredText?: boolean;
    hasSubtitles?: boolean;
    showFullScreenVideo?: boolean;
  };
  defaultMargins?: boolean;
}

export default function MediaSection(props: Props) {
  const { mediaSection, defaultMargins = true } = props;
  const [videoTime, setVideoTime] = useState(0);
  const [isButtonPlay, setIsButtonPlay] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const isMobile = useIsMobile();
  const videoRef = useRef();

  const handlePlaybutton = () => {
    if (!videoRef) return false;
    setIsButtonPlay(!isButtonPlay);
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handleMutebutton = () => {
    if (!videoRef) return false;
    setIsVideoMuted(!isVideoMuted);
    /*     if(videoRef.current.muted){
      videoRef.current.muted = true;
    } else {
      videoRef.current.muted = false;
    } */
  };

  function secondsToHms(secondsInput: number) {
    const pad = (n: any) => (n < 10 ? `0${n}` : n);
    const minutes = pad(Math.floor((secondsInput % 3600) / 60));
    const seconds = pad(Math.floor((secondsInput % 3600) % 60));
    return `${minutes}:${seconds}`;
  }

  // mediaSection.centeredText = true;
  /*   mediaSection.borderlessImage = true; */

  const allTextsPresent = !!(
    mediaSection?.heading &&
    mediaSection?.subtitle &&
    mediaSection?.paragraph
  );
  return (
    <Wrapper
      invertedLayout={mediaSection.invertedLayout}
      borderless={mediaSection.borderlessImage}
      defaultMargins={defaultMargins}
    >
      {!mediaSection?.showFullScreenVideo ? (
        <Text
          centeredText={mediaSection.verticallyCenteredText}
          invertedLayout={mediaSection.invertedLayout}
          allTextsPresent={allTextsPresent}
          borderless={mediaSection.borderlessImage}
        >
          <TextContent
            xl={mediaSection.xlImage}
            invertedLayout={mediaSection.invertedLayout}
            borderless={mediaSection.borderlessImage}
          >
            {mediaSection?.heading && <h5>{mediaSection.heading}</h5>}
            {mediaSection?.subtitle && <h6>{mediaSection.subtitle.subtitle}</h6>}
            {mediaSection?.paragraph && (
              <p style={mediaSection?.heading || mediaSection?.subtitle ? null : { paddingTop: 0 }}>
                {mediaSection.paragraph.paragraph}
              </p>
            )}
          </TextContent>
        </Text>
      ) : (
        <MediaTextWrapper>
          {!isMobile && mediaSection.heading && <Icons name="logo" color={colors.white} />}
          <MediaText centeredText={mediaSection.verticallyCenteredText}>
            <MediaTextContent centeredText={mediaSection.verticallyCenteredText}>
              {mediaSection?.heading && <h5>{mediaSection.heading}</h5>}
            </MediaTextContent>
          </MediaText>
          {mediaSection.heading && <Icons name="doodle2" color={colors.white} />}
        </MediaTextWrapper>
      )}
      <RelativeWrapper hasSubtitles={mediaSection?.hasSubtitles}>
        {videoRef.current && (
          <VideocontrolWrapper hasSubtitles={mediaSection?.hasSubtitles}>
            <PlayButton
              onClick={() => handlePlaybutton()}
              aria-label={isButtonPlay ? 'play video' : 'pause video'}
              type="button"
            >
              <Icons name={isButtonPlay ? 'play' : 'pause'} />
            </PlayButton>
            {mediaSection?.hasSubtitles && (
              <MuteButton
                onClick={() => handleMutebutton()}
                isVideoMuted={isVideoMuted}
                aria-label={isVideoMuted ? 'mute video' : 'play sound'}
                type="button"
              >
                <Icons name={isVideoMuted ? 'mute' : 'unmute'} />
              </MuteButton>
            )}
            {!mediaSection?.hasSubtitles && (
              <VideoTextWrapper>
                <VideoText>
                  {mediaSection.videoTitle
                    ? mediaSection.videoTitle
                    : mediaSection.video.title.replace('(Vimeo)', '')}
                </VideoText>
                <VideoText>
                  {`${secondsToHms(videoTime)} / ${secondsToHms(videoRef.current.duration)}`}
                </VideoText>
              </VideoTextWrapper>
            )}
          </VideocontrolWrapper>
        )}
        {mediaSection.image ? (
          <MediaCropper
            xl={mediaSection.xlImage}
            invertedLayout={mediaSection.invertedLayout}
            borderless={mediaSection.borderlessImage}
            fullWidth={mediaSection.heading && !mediaSection.paragraph}
          >
            <StyledImage fluid={mediaSection.image.fluid} />
          </MediaCropper>
        ) : (
          <MediaCropper
            xl={mediaSection.xlImage}
            fullWidth={mediaSection?.showFullScreenVideo}
            invertedLayout={mediaSection.invertedLayout}
            borderless={mediaSection.borderlessImage}
            xl={mediaSection.invertedLayout}
          >
            <VideoPlayer
              controls={false}
              autoPlay
              disableRemotePlayback
              loop
              muted={isVideoMuted}
              playsInline
              preload="meta"
              ref={videoRef}
              onTimeUpdate={e => setVideoTime(Math.round(videoRef.current.currentTime))}
            >
              <source
                src={`${
                  mediaSection.videoVimeo ? mediaSection.videoVimeo : mediaSection.video.file.url
                }`}
                type="video/mp4"
              />
            </VideoPlayer>
          </MediaCropper>
        )}
      </RelativeWrapper>
    </Wrapper>
  );
}

const MediaTextWrapper = styled.div`
  position: absolute;
  // left: ${mobileVW(10)};
  top: ${mobileVW(10)};
  z-index: ${zIndex.surface};
  display: flex;
  height: ${mobileVW(100)};
  align-items: center;
  width: unset;
  flex-direction: column;
  justify-content: center;
  top: ${desktopVW(0)};
  bottom: ${desktopVW(0)};
  height: unset;
  ${desktopBreakpoint} {
    left: ${desktopVW(0)};
    width: 100%;
  }
`;

const MediaText = styled.div<any>`
  position: relative;
  width: 100%;
  height: auto;
  z-index: ${zIndex.surface};
  display: flex;
  // justify-content: center;
  padding: ${mobileContentSidePadding} ${mobileContentSidePadding};
  align-items: ${({ centeredText }) => (centeredText ? 'center' : 'flex-start')};

  ${desktopBreakpoint} {
    width: ${desktopVW(1000)};
    flex-shrink: 0;
    height: auto;
    padding: ${desktopVW(1)} ${desktopVW(25)} ${desktopVW(45)} ${desktopVW(25)};
    margin-top: ${desktopVW(16)};
  }
`;

const MediaTextContent = styled.div<any>`
  position: relative;

  h5 {
    font-family: 'messina';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(82.8)};
    text-align: ${({ centeredText }) => (centeredText ? 'center' : 'left')};
    line-height: 90%;
    font-weight: 400;
    color: ${colors.white};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(170)};
      line-height: 90%;
    }
  }
`;

const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: ${({ invertedLayout }) => (invertedLayout ? 'flex-end' : 'space-between')};
  ${desktopBreakpoint}{
    flex-direction: ${({ invertedLayout }) => (invertedLayout ? 'row-reverse' : 'row')};
    padding: 0 ${({ borderless }) => (borderless ? '0' : desktopVW(90))} 0 ${({ borderless }) =>
  borderless ? '0' : desktopVW(90)};
    //padding: ${({ defaultMargins, borderless }: any) =>
      defaultMargins
        ? `${desktopVW(78)} ${borderless ? '0' : desktopVW(10)} ${desktopVW(58)} ${desktopVW(0)}`
        : `0`};
  }
`;

const Text = styled.div<any>`
  position: relative;
  width: 100%;
  height: auto;
  z-index: ${zIndex.surface};
  display: flex;
  justify-content: center;
  padding: ${mobileContentSidePadding} ${mobileContentSidePadding};

  ${desktopBreakpoint} {
    width: ${({ borderless }) => (borderless ? '28.5vw' : '33.3vw')};
    flex-shrink: 0;
    height: auto;
    padding: ${desktopVW(1)} ${desktopVW(25)} ${desktopVW(45)} ${desktopVW(25)};
    align-items: ${({ centeredText }) => (centeredText ? 'center' : 'flex-start')};
  }
`;

const TextContent = styled.div<any>`
  position: relative;

  h5 {
    font-family: 'messinaMono';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(27)};
    font-weight: 400;

    ${desktopBreakpoint} {
      font-size: ${desktopVW(30)};
    }
  }

  h6 {
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    text-decoration: underline;
    font-weight: 300;
    font-size: ${mobileVW(23)};
    padding: ${mobileVW(30)} 0 0 0;

    ${desktopBreakpoint} {
      font-size: ${desktopVW(20)};
      padding: ${desktopVW(20)} 0 0 ${desktopVW(3)};
      width: ${({ borderless, xl }) => (borderless ? (xl ? '90%' : '90%') : '80%')};
      line-height: 140%;
    }
  }

  p {
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-weight: 300;
    color: ${colors.subtleGrey};
    padding: ${mobileVW(30)} 0 0 0;
    width: 75%;
    font-size: ${mobileVW(16)};
    white-space: pre-line;

    ${desktopBreakpoint} {
      font-size: ${desktopVW(14.5)};
      padding: ${desktopVW(23)} 0 0 ${desktopVW(3)};
      width: ${({ borderless, xl }) => (borderless ? (xl ? '95%' : '85%') : '67%')};
      line-height: ${desktopVW(21)};
    }
  }

  ${desktopBreakpoint} {
    width: ${({ borderless }) => (borderless ? '90%' : '85%')};
  }
`;

const VideoPlayer = styled.video`
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: ${mobileVW(450)};
  z-index: ${zIndex.background};
  &::-webkit-media-controls {
    display: block;
    -webkit-appearance: block;
  }

  ${desktopBreakpoint} {
    //position: absolute;
    height: 100%;
    width: 100%;
    left: auto;
    top: 0;
    right: 0;
  }
`;

const MediaCropper = styled.div<any>`
  height: 100%;
  width: 100%;
  overflow: hidden;
  ${desktopBreakpoint} {
    //width: 66.5vw;
    width: ${({ borderless, xl, fullWidth }) =>
      fullWidth ? '100vw' : borderless ? (xl ? desktopVW(975) : desktopVW(800)) : desktopVW(750)};
    height: ${desktopVW(750)};
  }
`;

const StyledImage = styled(Img)`
  width: 100%;
  z-index: ${zIndex.background};
`;

const VideocontrolWrapper = styled.div`
  position: absolute;
  left: ${mobileVW(10)};
  bottom: ${mobileVW(10)};
  z-index: ${zIndex.surface};
  display: flex;
  flex-direction: ${({ hasSubtitles }) => (hasSubtitles ? 'column-reverse' : 'row')};
  height: ${mobileVW(100)};
  align-items: center;
  justify-content: space-between;
  width: unset;
  ${desktopBreakpoint} {
    flex-direction: row;
    left: ${desktopVW(30)};
    bottom: ${desktopVW(30)};
    height: unset;
    // width: ${desktopVW(110)};
  }
`;

const PlayButton = styled.button`
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${mobileVW(60)};
  height: ${mobileVW(60)};

  ${desktopBreakpoint} {
    width: ${desktopVW(60)};
    height: ${desktopVW(60)};
  }
`;

const MuteButton = styled.button`
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${mobileVW(60)};
  height: ${mobileVW(60)};
  padding: ${mobileVW(10)} ${({ isVideoMuted }) => (isVideoMuted ? mobileVW(15) : mobileVW(10))}
    ${mobileVW(10)} ${mobileVW(10)};
  ${desktopBreakpoint} {
    padding: ${desktopVW(10)}
      ${({ isVideoMuted }) => (isVideoMuted ? desktopVW(16) : desktopVW(10))} ${desktopVW(10)}
      ${desktopVW(10)};

    width: ${desktopVW(60)};
    height: ${desktopVW(60)};
  }
`;

const VideoText = styled.div`
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  font-size: ${mobileVW(16)};
  color: ${colors.white};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(13)};
  }
`;

const VideoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-left: ${mobileVW(20)};
  ${desktopBreakpoint} {
    padding-left: ${desktopVW(20)};
  }
`;

const RelativeWrapper = styled.div`
  position: relative;

  video {
    height: ${hasSubtitles => (hasSubtitles ? 'auto' : `${mobileVW(450)}`)};
  }

  button {
    width: ${hasSubtitles => (hasSubtitles ? `${mobileVW(45)}` : `${mobileVW(60)}`)};
    height: ${hasSubtitles => (hasSubtitles ? `${mobileVW(45)}` : `${mobileVW(60)}`)};
    ${desktopBreakpoint} {
      width: ${hasSubtitles => (hasSubtitles ? `${desktopVW(50)}` : `${desktopVW(60)}`)};
      height: ${hasSubtitles => (hasSubtitles ? `${desktopVW(50)}` : `${desktopVW(60)}`)};
    }
  }

  ${desktopBreakpoint} {
    button {
      visibility: ${hasSubtitles => (hasSubtitles ? 'hidden' : 'visible')};
    }
    &:hover {
      button {
        visibility: visible;
      }
    }
  }
`;
